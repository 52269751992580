import { render, staticRenderFns } from "./svg_icons.vue?vue&type=template&id=de3c097c&"
import script from "./svg_icons.vue?vue&type=script&lang=js&"
export * from "./svg_icons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/chroot/home/protectivesleeve/dev.protectivesleeve.ca/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de3c097c')) {
      api.createRecord('de3c097c', component.options)
    } else {
      api.reload('de3c097c', component.options)
    }
    module.hot.accept("./svg_icons.vue?vue&type=template&id=de3c097c&", function () {
      api.rerender('de3c097c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "html/js/src/component/svg_icons.vue"
export default component.exports